import { BodyMeasurementRequestDto } from "../../models";
import { BodyMeasurementDto, GenderTypeDto } from "../../swagger";
import { AuthConstants } from "../constants";
export const AuthActions = {
  storageUpdate,
  storageFemaleUpdate,
  storageMaleUpdate,
  storageUpdateAfterCalculator,
};
function storageUpdate(data: BodyMeasurementRequestDto) {
  return { type: AuthConstants.STORAGE_UPDATE, data };
}
function storageFemaleUpdate(data: BodyMeasurementRequestDto) {
  return { type: AuthConstants.STORAGE_FEMALE, data };
}

function storageMaleUpdate(data: BodyMeasurementRequestDto) {
  return { type: AuthConstants.STORAGE_MALE, data };
}
function storageUpdateAfterCalculator(
  data?: BodyMeasurementDto,
  gender?: GenderTypeDto
) {
  return { type: AuthConstants.STORAGE_CAL, data, gender };
}
